var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "국내사업",
      "tabActive": "교육사업",
      "bg": "/images/sub/visual/sv2.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('page-title', {
    attrs: {
      "tit": "교육사업",
      "sc": "사각지대에 놓인 지역 주민들의 삶의 질 향상을 위해 지역사회 내 사회복지 시설의 신규 유치 및 운영을 지원하고, 최근 사회복지 동향에 대한 전문 정보의 공유를 통해 사회복지의 현대화·전문화에 기여함으로써 사회복지 활동 영역의 기반확대, 사회적 위상 제고"
    }
  }), _c('v-sheet', {
    staticClass: "mb-n40 mb-lg-n60"
  }, _vm._l(_vm.info, function (item) {
    return _c('v-sheet', {
      key: item.title,
      staticClass: "mb-40 mb-lg-60"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "mb-10 mb-md-0",
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('v-img', {
      staticClass: "w-100 mx-auto",
      attrs: {
        "src": item.image,
        "max-width": "282",
        "aspect-ratio": 282 / 200
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "9"
      }
    }, [_c('h4', {
      staticClass: "tit tit--sm line-height-1"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-divider', {
      staticClass: "my-12 my-lg-16"
    }), _c('p', {
      staticClass: "page-text line-height-17",
      domProps: {
        "innerHTML": _vm._s(item.sc)
      }
    })], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
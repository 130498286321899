<template>
    <client-page>

        <sub-visual
        sh="국내사업"
        tabActive="교육사업"
        bg="/images/sub/visual/sv2.jpg">
        </sub-visual>

        <section class="section">
            <v-container>
                <page-title
                tit="교육사업"
                sc="사각지대에 놓인 지역 주민들의 삶의 질 향상을 위해 지역사회 내 사회복지 시설의 신규 유치 및 운영을 지원하고, 최근 사회복지 동향에 대한 전문 정보의 공유를 통해 사회복지의 현대화·전문화에 기여함으로써 사회복지 활동 영역의 기반확대, 사회적 위상 제고" />
                
                <v-sheet class="mb-n40 mb-lg-n60">
                    <v-sheet v-for="item in info" :key="item.title" class="mb-40 mb-lg-60">
                        <v-row>
                            <v-col cols="12" md="3" class="mb-10 mb-md-0">
                                <v-img :src="item.image" max-width="282" :aspect-ratio="282 / 200" class="w-100 mx-auto"/>
                            </v-col>
                            <v-col cols="12" md="9">
                                <h4 class="tit tit--sm line-height-1">
                                    {{item.title}}
                                </h4> 
                                <v-divider class="my-12 my-lg-16" />
                                <p v-html="item.sc" class="page-text line-height-17"></p>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-sheet>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageTitle from "@/components/client/sub/page-title.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
        PageTitle,
    },    
    data() {
        return {
            info : [
                {
                    title : "신입직원교육",
                    sc : "사회복지법인 위드캔복지재단은 1989년부터 역사를 지니고 있음. 그동안 많은 직원들이 재단에 입사하여 헌신한 결과 지금의 재단을 이룰 수 있었음. 이와 관련하여 신입직원을 대상으로 재단의 비전과 사명을 이해하고 개인의 역량강화 프로그램을 통해 기관에서 전문적이고 열정적인 모습으로 일 할 수 있도록 신입직원교육을 진행",
                    image : "/images/sub/business/education/education-img.jpg",
                },
                {
                    title : "중간관리자 교육",
                    sc : "중간관리자 교육은 각 시설의 중간관리자에게 현재의 사회복지의 이슈와 해결방안, 비전 등을 제안하는 전문가를 통해 소진된 역량을 격려하고 강화시키기 위함.",
                    image : "/images/sub/business/education/education-img2.jpg",
                },
                {
                    title : "시설장 교육 및 연수",
                    sc : "사회복지를 선도하는 핵심리더들을 대상으로 급변하는 사회복지 환경에 적극적으로 대응할 수 있도록 사회복지정책 방향을 전달하고 정보를 교류할 수 있는 자리를 마련함으로써, 소통과 화합을 통해 사회복지의 발전방향을 공유하고 사회복지시설의 동반성장을 모색함.",
                    image : "/images/sub/business/education/education-img3.jpg",
                },
                {
                    title : "회계교육",
                    sc : "시설의 특성과 회계담당자의 근무경력에 따른 각기 다른 회계처리 방식으로 회계보고에 있어 재단과의 혼선이 있어 업무의 효율성과 회계담당자들의 역량강화를 위해 실시. 회계담당자의 업무매뉴얼을 만들게 하고 재단 회계보고 양식을 일괄적으로 통일시키는 교육들을 통해 많은 수준향상을 보임. 회계교육 사업은 교육의 의미와 더불어 회계담당자들의 노고를 격려.",
                    image : "/images/sub/business/education/education-img4.jpg",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
</style>